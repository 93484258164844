<template>
  <PageWithLayout
    :isBodyMiddle="!isPasswordCheck">
    <CheckPassword
      v-if="!isPasswordCheck"
      :isPasswordCheck.sync="isPasswordCheck"/>
    <template v-else>
      <Search
        :searchDataList.sync="viewModel.searchDataList"
        @onSearch="viewModel.onSearch()"/>
      <Board
        :boardData="viewModel.boardData"
        :searchParams.sync="viewModel.searchParams"
        :dataList="viewModel.dataList"
        :paginationData="viewModel.paginationData"
        :emptyDesc="viewModel.searchDataList.value2 ? '검색결과가 없습니다' : '회원정보를 검색해주세요'"
        @onClickRow="rowId => viewModel.onClickRow(rowId)"
        @onSearch="viewModel.onSearch()">
      </Board>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import CheckPassword from '@lemontree-ai/lemontree-admin-common-front/components/common/checkPassword/CheckPassword';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// viewModel
import UserListViewModel from '@/views/service/user/viewModel/UserListViewModel'

export default {
  name:'UserAllList',
  components:{
    PageWithLayout,
    CheckPassword,
    Search,
    Board,
    Button,
    IconSvg,
  },
  watch:{
    isPasswordCheck(){
      if(this.isPasswordCheck){
        this.viewModel.init();
      }
    }
  },
  beforeMount(){
    this.viewModel.userAdminType = this.$route.meta.lnbMenuData.id
  },
  data(){
    return{
      viewModel: new UserListViewModel(),
      isPasswordCheck:false,
    }
  },
}
</script>

<style scoped>
.board_comm::v-deep .fold .tit_question{display:block;padding-bottom:9px;line-height:20px}
.board_comm::v-deep .fold span{display:block;height:29px;margin:9px 0 0;overflow:hidden;line-height:22px;white-space:nowrap;text-overflow:ellipsis}
.board_comm::v-deep .tr_fold span{height:auto;margin-bottom:9px;overflow:inherit;white-space:inherit;text-overflow:inherit}
.board_comm::v-deep .tr_fold .tit_question{color:#222}
/* .board_comm::v-deep .icon_firfin{margin-top:10px}
.board_comm::v-deep .tr_fold .icon_firfin{transform:scaleY(-1)} */
</style>